<template>
    <div :style="'height:'+ innerHeight + 'px;vertical-align: bottom;'">
        <div class="bg"></div>
         <div class="e">
             <Card  style="width:500px;border: 1px solid #eeeeee; " shadow  bordered title="登录">
                <div style="margin:20px 30px;text-align:center">
                    <Form ref="formInline" :model="formInline" :rules="ruleInline" >
                        <FormItem prop="account">
                            <Input type="text" v-model="formInline.account" placeholder="请输入账户">
                                <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem prop="pwd">
                            <Input type="password" v-model="formInline.pwd" placeholder="请输入密码">
                                <Icon size="18" type="ios-lock-outline"  slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" @click="handleSubmit('formInline')">登录</Button>
                        </FormItem>
                    </Form>
                </div>
            </Card>
         </div>
    </div>
</template>
<script>
import { login } from "@/api/index";
import {setCookies} from '@/utils/helper'
export default {
  data() {
    return {
      innerHeight: 0,
      formInline: {
        account: '',
        pwd: ''
      },
      ruleInline: {
        account: [
          {required: true, message: '账户不能为空', trigger: 'blur'}
        ],
        pwd: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    this.restHeight();
  },
  created() {
    var _this = this
    document.onkeydown = function () {
      if (_this.$route.name === '登录') {
        let key = window.event.keyCode
        if (key === 13) {
          _this.handleSubmit('formInline')
        }
      }
    }
  },
  methods: {
    /**修改高度 */
    restHeight() {
      this.innerHeight = window.innerHeight - 2;
    },
    handleSubmit(name) {
        let menu = [
            {
                children: [],
                icon: "md-home",
                id: 1,
                is_menu: 1,
                pid: 0,
                src: "/admin",
                title: "首页",
                unique_auth: "home"
            },
            {
                children: [],
                icon: "md-list-box",
                id: 2,
                is_menu: 1,
                pid: 0,
                src: "/admin/placeorder",
                title: "在线订单",
                unique_auth: "placeorder"
            },
            {
                children: [],
                icon: "md-headset",
                id: 3,
                is_menu: 1,
                pid: 0,
                src: "/admin/consultrate",
                title: "咨询订单",
                unique_auth: "consultrate"
            },
            {
                children: [],
                icon: "md-car",
                id: 4,
                is_menu: 1,
                pid: 0,
                src: "/admin/carsetup",
                title: "车辆设置",
                unique_auth: "carsetup"
            },
            {
                children: [
                    {
                        id: 6,
                        is_menu: 2,
                        pid: 0,
                        src: "/systemsetup/homeset",
                        title: "首页设置",
                        unique_auth: "homeset"
                    },
                    {
                        id: 7,
                        is_menu: 2,
                        pid: 0,
                        src: "/systemsetup/pagecontent",
                        title: "页面内容",
                        unique_auth: "pagecontent"
                    },
                    {
                        id: 8,
                        is_menu: 2,
                        pid: 0,
                        src: "/systemsetup/systemconfig",
                        title: "系统配置",
                        unique_auth: "systemconfig"
                    },
                    // {
                    //     id: 9,
                    //     is_menu: 2,
                    //     pid: 0,
                    //     src: "/systemsetup/aboutus",
                    //     title: "关于我们",
                    //     unique_auth: "aboutus"
                    // },
                    {
                        id: 10,
                        is_menu: 2,
                        pid: 0,
                        src: "/systemsetup/changepassword",
                        title: "修改密码",
                        unique_auth: "changepassword"
                    },
                ],
                icon: "md-settings",
                id: 5,
                is_menu: 1,
                pid: 0,
                src: "/admin/systemsetup",
                title: "系统设置",
                unique_auth: "systemsetup"
            },
        ]
        this.$refs[name].validate((valid) => {
            if (valid) {
                login(this.formInline).then(res=>{ 
                    setCookies('authori-zation-huoyun', res.data.token)
                    localStorage.setItem("userName", JSON.stringify(res.data.account));
                    this.$store.commit('setMenus', menu)
                    this.$Message.success(res.msg);
                    this.$router.push({path: '/admin'})
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            } else {
                this.$Message.error('数据不能为空');
            }
        })
    }
  }
}
</script>
<style scoped>
.ivu-icon{
  width:30px
}
.e{
  margin-top:-20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
}
.bg{
  background-image: url("../../assets/login/bg.jpg");
  background-size: 100% 100%;
  width:100%;
  height:100%;
  position:absolute;
  z-index:1;
}
</style>
